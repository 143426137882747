import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';

const GetImage = ({ icon }: { icon: string }) => {
  const firebaseStorage = getStorage();
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState<string | null>(null);

  const getImageUrl = async () => {
    try {
      if (icon) {
        const url = await getDownloadURL(ref(firebaseStorage, icon));
        setImageUrl(url);
      } else {
        setError('Icon path is empty');
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  };

  useEffect(() => {
    getImageUrl();
  }, [icon]);

  return (
    <div>
      {error ? (
        <div>Error: {error}</div>
      ) : (
        <img src={imageUrl} alt="Firebase storage content" />
      )}
    </div>
  );
};

export default GetImage;
