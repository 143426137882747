import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContentContext } from "../../context/ContentContext";
import moment from "moment";

const { Title } = Typography;

const QuestionEdit = () => {
  const params = useParams();
  const question_id = params.id || "";
  const { Toast } = useContentContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");
  const [btnType, setBtnType] = useState("save");
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList] = useState<any[]>([]);


  const { state } = useLocation();
  const initialValues = {
    category: state?.category || "",
  };
  const answerOptions = [
    { value: "op1", label: "Option 1" },
    { value: "op2", label: "Option 2" },
    { value: "op3", label: "Option 3" },
    { value: "op4", label: "Option 4" },
  ];

  const showDrawer = () => setOpen(true);

  const onClose = () => setOpen(false);

 const getCategoryData = async () => {
    try {
      let apiRes = await seekSolutionApi.Category.list();
      console.log("apiRes get category->", apiRes);
      if (apiRes) {

        const newState = {
          Count: apiRes.count,
          ScannedCount: apiRes.data.length,
          Items: apiRes.data.map((item: any) => ({
            id: item._id,
            name: item.name,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setCategory(newState);
        console.log('newstaet', newState);

      } else {
        console.log('Data not found');
      }
    } catch (error) { }
  };

  useEffect(() => {
    getCategoryData();
    getQuestionsList();
    getData(question_id);
  }, [question_id]);

  const initialise = async (values: any) => {
    try {
      setLoading(btnType);
      const apiRes = await seekSolutionApi.Questions.update(question_id, values);
      form.resetFields();

      if (apiRes) {
        Toast.openSuccessNotification("Successfully Updated");
      }
      if (btnType === "saveExit") {
        window.history.back();
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error("Failed to update question:", error);
    } finally {
      setLoading("");
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
    };
    await initialise(formData);
  };
  const initialvalues = {
    name: "",
    isFooter: false
  };
  const getQuestionsList = () => {
    console.log("Function running");
    let list: any = [];
    category.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.name });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  const getData = async (question_id: string) => {
    try {
      const apiRes = await seekSolutionApi.Questions.get(question_id);
      if (apiRes?._id) {
        const { question, op1, op2, op3, op4, ans, category } = apiRes;
        form.setFieldsValue({
          question,
          op1,
          op2,
          op3,
          op4,
          ans,
          category,
        });
      }
    } catch (error) {
      console.error("Failed to fetch question data:", error);
    }
  };
  console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/questions/page/1`}>Questions</Link>,
            },
            {
              title: "Update",
            },
          ]}
        />
        <Title level={2}>Question</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: 'Question is required' }]}
          >
            <Input placeholder="What is 12 ?" />
          </Form.Item>

          <Form.Item
            name="op1"
            label="Option 1"
            rules={[{ required: true, message: 'Option 1 is required' }]}
          >
            <Input placeholder="Object" />
          </Form.Item>

          <Form.Item
            name="op2"
            label="Option 2"
            rules={[{ required: true, message: 'Option 2 is required' }]}
          >
            <Input placeholder="Constant" />
          </Form.Item>

          <Form.Item
            name="op3"
            label="Option 3"
            rules={[{ required: true, message: 'Option 3 is required' }]}
          >
            <Input placeholder="Variable" />
          </Form.Item>

          <Form.Item
            name="op4"
            label="Option 4"
            rules={[{ required: true, message: 'Option 4 is required' }]}
          >
            <Input placeholder="Keyword" />
          </Form.Item>

          <Form.Item
            name="ans"
            label="Answer"
            rules={[{ required: true, message: 'Answer is required' }]}
          >
            <Select placeholder="Select which option is correct" options={answerOptions} />
          </Form.Item>

          <Form.Item
            name="category"
            label="Category"
            required
            tooltip="Category is required"
          >
            <Select defaultValue={initialValues.category}>
              {category.Items.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Space size="middle">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading === "save"}
              onMouseOver={() => setBtnType("save")}
            >
              Update
            </Button>
            {/* Uncomment this button if needed
            <Button
              type="primary"
              htmlType="submit"
              loading={loading === "saveExit"}
              onMouseOver={() => setBtnType("saveExit")}
            >
              Create and Exit
            </Button> */}
          </Space>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default QuestionEdit;
