import { Modal } from "antd"
import { useLocation, useNavigate } from "react-router-dom"

const CheckVerificationOfPayment = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const uRLSearchParams = new URLSearchParams(location.search)

    return <Modal open={uRLSearchParams.has("payment_intent")} centered onCancel={() => navigate({ pathname: location.pathname, search: "" })}
        footer={null}

    >
        <svg
            width="115px"
            height="115px"
            viewBox="0 0 133 133"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g
                id="check-group"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <circle
                    id="filled-circle"
                    fill="#07b481"
                    cx="66.5"
                    cy="66.5"
                    r="54.5"
                />
                <circle
                    id="white-circle"
                    fill="#FFFFFF"
                    cx="66.5"
                    cy="66.5"
                    r="55.5"
                />
                <circle
                    id="outline"
                    stroke="#07b481"
                    stroke-width="4"
                    cx="66.5"
                    cy="66.5"
                    r="54.5"
                />
                <polyline
                    id="check"
                    stroke="#FFFFFF"
                    stroke-width="5.5"
                    points="41 70 56 85 92 49"
                />
            </g>
        </svg>
    </Modal>
}

export default CheckVerificationOfPayment