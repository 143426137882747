
import React, { Fragment, useState, useEffect } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Flex,
} from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { useContentContext } from "../../context/ContentContext";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { IQuestion } from "../../context/interfaces";

const { Title } = Typography;
const { Search } = Input;

const QuestionListing = () => {
  // const history = useHistory();
  const { Toast } = useContentContext();
  const [defaultState, setDefaultState] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [state, setState] = useState({ ...defaultState });
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [open, setOpen] = useState(false);
  // const history = use

  console.log("state>",state);
  

  const columns: ColumnsType<any> = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Options",
      key: "options",
      render: (_: any, record: IQuestion) => <Flex vertical>
        <div>1. {record.op1}</div>
        <div>2. {record.op2}</div>
        <div>3. {record.op3}</div>
        <div>4. {record.op4}</div>
      </Flex>

    },
    {
      title: "Answer",
      dataIndex: "ans",
      key: "ans",
      // render: (date: string) => moment(date).format("MMM, DD YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("MMM, DD YYYY"),
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/questions/${record._id}/edit` }} state={record}>
            <Button danger size="small" >
              <EditOutlined />
            </Button>
          </Link>

          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              handleDelete(record._id);
            }}
          >
            <Button danger size="small" loading={loadingId === record._id}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async () => {
    
    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Questions.list();
      console.log("apiRes list==", apiRes);
      const newState = {
        Count: 0,
        ScannedCount: 0,
        Items: apiRes.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
        })),
      };

      console.log("newState=>",newState);
      
      
      setState(newState);
      setDefaultState(newState);
    } catch (error) { }
  };

  const handleDelete = async (_id: any) => {
    try {
      setLoading(true);
      setLoadingId(_id);
      let apiRes = await seekSolutionApi.Questions.delete(_id);
      if (apiRes) {
        console.log("deleted id", apiRes);
        initialise();
        Toast.openSuccessNotification("Item Deleted Successfully");
      }
    } catch (error) {
      console.error("Error deleting question", error);
    } finally {
      setLoading(false);
      setLoadingId("");
    }
  };


  useEffect(() => {
    initialise();
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredItems = defaultState?.Items?.filter((item: any) =>
      item.question.toLowerCase().startsWith(value.toLowerCase())
    );
    setState((prevState) => ({ ...prevState, Items: filteredItems }));
  };

  const resetSearch = () => {
    setSearchValue("");
    setState({ ...defaultState });
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: "Question",
            },
          ]}
        />
        <Title level={2}>Question</Title>
        <Divider />
        <Row gutter={[2, 8]} justify={"space-between"}>
          <Col span={20}>
            <Search
              placeholder="input search text"
              enterButton="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <Col
            span={4}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <Button type="primary" onClick={showDrawer}>
              Filter
            </Button>
            <Button onClick={resetSearch}>Reset</Button>
            <a href="/questions/create">
              <PlusCircleOutlined style={{ fontSize: "28px" }} />
            </a>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={state.Items.map((res: any, index) => ({
            ...res,
            key: index + 1,
            name: res.name,
           
            createdAt: moment(res.createdAt).format("MMM, DD YYYY"),
          }))}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some Category...</p>
      </Drawer>
    </Fragment>
  );
};

export default QuestionListing;
