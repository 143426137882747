import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  InputRef,
  InputNumber,
  Upload,
  message,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import { ICategory } from "../../context/interfaces";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const { Title } = Typography;
const { Option } = Select;

const CoursesCreate = () => {
  const [btnType, setBtnType] = useState("save");
  const firebaseStorage = getStorage();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const categoryInputRef = useRef<InputRef>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const [category, setCategory] = useState<{
    count: number;
    data: Array<ICategory>;
    text: string;
  }>({
    count: 0,
    data: [],
    text: "",
  });

  const [topic, setTopic] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
    text: "",
  });

  const addCategory = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    try {
      setLoading(true);
      const apiRes = await seekSolutionApi.Category.create({
        name: category.text,
      });
      setCategory({
        ...category,
        data: [...category.data, apiRes] as any,
        text: "",
      });
      setTimeout(() => {
        categoryInputRef.current?.focus();
      }, 0);
    } catch (error) {
      message.error("Failed to add category.");
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
    try {
      if (!values.icon) {
        return;
      }

      const singleFile = values.icon.file.originFileObj;
      const storageRef = ref(
        firebaseStorage,
        "users/" + new Date().getTime() + singleFile.name
      );
      const uploaded = await uploadBytes(storageRef, singleFile);

      setLoading(btnType === "save");
      const apiRes = await seekSolutionApi.Courses.create({
        ...values,
        icon: uploaded.ref.fullPath,
      });
      localStorage.removeItem("courses_draft");
      form.resetFields();
      navigate(`/courses/${apiRes._id}/sections/page/1`, {
        replace: true,
      });
    } catch (error) {
      message.error("Failed to create course.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    localStorage.setItem("courses_draft", JSON.stringify(values));

    await initialise(values);
    setLoading(false);
  };

  const initialiseCategory = async () => {
    try {
      const apiRes = await seekSolutionApi.Category.list();
      setCategory({ ...apiRes, text: "" });
    } catch (error) {
      message.error("Failed to load categories.");
    }
  };

  const initialiseTopic = async (category_id: string) => {
    if (!category_id) {
      return;
    }
    try {
      const apiRes = await seekSolutionApi.Topic.list();
      setTopic(apiRes);
    } catch (error) {
      message.error("Failed to load topics.");
    }
  };

  useEffect(() => {
    initialiseCategory();
  }, []);

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj;
      setImageUrl(URL.createObjectURL(obj));
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: <Link to={`/courses/page/1`}>Courses</Link> },
            { title: "Create" },
          ]}
        />
        <Title level={2}>Courses</Title>
        <Divider />
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...JSON.parse(localStorage.getItem("courses_draft") as string),
          }}
          layout="vertical"
        >
          <Form.Item
            name={"icon"}
            label=""
            required
            tooltip="This is a required field"
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*"
              action={"#"}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            name={"title"}
            label="Title"
            required
            tooltip="This is a required field"
          >
            <Input placeholder="The Complete Python Bootcamp From Zero to Hero in Python" />
          </Form.Item>

          <Form.Item
            name={"price"}
            label="Price"
            required
            tooltip="Price is required"
          >
            <InputNumber
              prefix="₹ "
              style={{ width: "100%" }}
              placeholder="499"
            />
          </Form.Item>

          <Form.Item
            name={"desc"}
            label="Sort description"
            required
            tooltip="This is a required field"
          >
            <Input.TextArea placeholder="Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games" />
          
          </Form.Item>

          <Form.Item
            name={"rate"}
            label="Select course rate"
            required
            tooltip="This is a required field"
          >
            <Input.TextArea placeholder="Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games" />
          
            {/* <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select languages"
              optionLabelProp="label"
            >
              <Option value="c">C Programming</Option>
              <Option value="java">Java Programming</Option>
              <Option value="python">Python Programming</Option>
            </Select> */}
          </Form.Item>

          <Form.Item
            name={"categoryId"}
            label="Category"
            required
            tooltip="This is a required field"
          >
            <Select
              style={{ width: "100%" }}
              onChange={(e) => initialiseTopic(e)}
              options={category?.data?.map((res) => ({
                value: res._id,
                label: res.name,
              }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      ref={categoryInputRef}
                      value={category.text}
                      disabled={loading}
                      onChange={(e) =>
                        setCategory({
                          ...category,
                          text: e.target.value,
                        })
                      }
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addCategory}
                      loading={loading}
                    >
                      Add new category
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>

          <Form.Item
            name={"link"}
            label="Link"
            required
            tooltip="This is a required field"
          >
            <Input placeholder="https://courses.postmortemshala.co.in/courses/Data-Structure-using-C-Language-A-to-Z-6671a50cbd21c36e91b786b5" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default CoursesCreate;
