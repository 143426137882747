import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Upload,
  message,
  Select,
  InputRef,
  DatePicker,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ICategory, IEVENT_TYPE } from "../../context/interfaces";
import moment from "moment";
const { Title } = Typography;

const EventsEdit = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string>();
  const firebaseStorage = getStorage();
  const categoryInputRef = React.useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [eventTypesData, setEventTypesData] = useState<Array<{ label: string; value: string }>>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    initialiseEventTypes();
  }, []);

  const initialValues = {
    title: state.title,
    desc: state.desc,
    date: dayjs(state.date),
    link: state.link,
    thumbnail: state.thumbnail,
    eventType: state.eventType,
  };

  console.log('initialValues->',initialValues);
  
  const onClose = () => setOpen(false);

  const initialiseEventTypes = async () => {
    try {
      const apiRes = await seekSolutionApi.Events.list();
      if (apiRes?.length > 0) {
        const selectData = apiRes.map((item: IEVENT_TYPE) => ({
          label: item.title,
          value: item.slug,
        }));
        setEventTypesData(selectData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const initialise = async (values: any) => {
    setLoading(true);
    try {
      if (!values.thumbnail || !values.thumbnail.file) {
        throw new Error("No thumbnail provided or file is missing");
      }
      const singleFile = values.thumbnail.file.originFileObj

      const storageRef = ref(firebaseStorage, 'events/' + new Date().getTime() + singleFile.name);

      const uploaded = await uploadBytes(storageRef, singleFile)
      console.log("uploaded", uploaded.ref.fullPath);

      const downloadURL = await getDownloadURL(storageRef);

      const apiRes = await seekSolutionApi.Events.update(state._id, {
        ...values,
        thumbnail:uploaded.ref.fullPath,
      });

      console.log('state', state);

      form.resetFields();

      if (apiRes) {
        navigate(-1);
      }
    } catch (error:any) {
      if(error.response) {
       
        console.error("Server Error:", error.response.data);
      } else {
      
        console.error("Error updating event:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    console.log('values',values);
    const formData = {
      ...values,
      date: dayjs(values.date).valueOf(),
      // Ensure date is in ISO format
    };
    console.log('formdata', formData);

    setLoading(true);
    localStorage.setItem("events_draft", JSON.stringify(values));
    await initialise(formData);
    setLoading(false);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }
    return true;
  };

 
  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj
      setImageUrl(URL.createObjectURL(obj))
    }
  };

  const getBase64 = (img: Blob, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: <Link to={`/events/page/1`}>Events</Link> },
            { title: "Edit" },
          ]}
        />
        <Title level={2}>Edit Events</Title>

        <Divider />
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item name="title" label="Title" required tooltip="Title is required">
            <Input placeholder="Enter the event title" />
          </Form.Item>
          <Form.Item name="desc" label="Description" tooltip="Description is required">
            <Input.TextArea placeholder="Enter the event description" />
          </Form.Item>
          <Form.Item name="date" label="Select event date & time" required tooltip="Date is required">
            <DatePicker
              style={{ width: "100%" }}
              format={"YYYY-MM-DD HH:mm"}
              showTime={{ format: "HH:mm" }}
              placeholder="Date & Time"
            />
          </Form.Item>
          <Form.Item name="link" label="Event Link" tooltip="Event Link">
            <Input placeholder="https://meet.google.com/uey-ydmb-eaj" />
          </Form.Item>
          <Form.Item name="eventType" label="Event Type" required tooltip="Event Type is required">
            <Select placeholder="Select Event Type" options={eventTypesData} />
          </Form.Item>
         
          <Form.Item name="thumbnail" label="Thumbnail" required tooltip="This is a required field">
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*"
              fileList={fileList}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>


          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>Apply</Button>
          </Space>
        }
      >
        <p>Some Events...</p>
      </Drawer>
    </Fragment>
  );
};

export default EventsEdit;
