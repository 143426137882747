import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  DatePicker,
  TimePicker,
  UploadProps,
  message,
  Upload,
  Switch,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import LanguageCode from "../../utils/ISO_639-2.json";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useContentContext } from "../../context/ContentContext";
import ReactQuill from "react-quill";
import { values } from "lodash";
const { Title } = Typography;

const TrainingCreate = () => {
  let { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [colleges, setColleges] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [type, setType] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [user, setUser] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList]: any = useState([]);

  console.log("user=>",user);
  

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getCollegesData = async () => {
    try {
      let apiRes = await seekSolutionApi.colleges.list();
      if (apiRes) {
        console.log("apiRes get colleges->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            name: item.name,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setColleges(newState);
      }
    } catch (error) { }
  };
  const getProgramsData = async () => {
    try {
      let apiRes = await seekSolutionApi.Programs.list();
      console.log('programs->dta',apiRes);
      
      if (apiRes) {
        console.log("apiRes get colleges->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            title: item.title,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setType(newState);
      }
    } catch (error) { }
  };
  
  const getRegistrationUserData = async () => {
    try {
      
      
      let apiRes = await seekSolutionApi.Users.list();
      console.log('AUTH-API',apiRes);
      
      if (apiRes) {
        console.log("apiRes get colleges->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            firstName: item.firstName,
            lastName: item.lastName,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setUser(newState);
      
        
      }
    } catch (error) { }
  };

  useEffect(() => {
    getCollegesData();
    getProgramsData()
    getRegistrationUserData()
    getTopicsList();
  }, []);

  const initialise = async (values: any) => {
    console.log("Values --->", values);

    try {
      
      
      setLoading(true);
      const apiRes = await seekSolutionApi.Trainings.create(values);
      console.log("API Response:", apiRes);
      form.resetFields();
      navigate(-1);
      if (apiRes) {
        Toast.openSuccessNotification("Successfully Created");
        console.log("API Response Categories:", apiRes);
      }
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
      dob: moment(values.dob).valueOf(),
    };

    console.log('Fvalues', formData);


    await initialise(formData);
  };

  const initialvalues = {
    name: "",
    isFooter: false
  };

  const getTopicsList = () => {
    console.log("Function running");
    let list: any = [];
    colleges.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.name });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/training/page/1`}>Training</Link>,
            },
            {
              title: "Create",
            },
          ]}
        />
        <Title level={2}>Training</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >


          <Form.Item
            name={"firstName"}
            label="First Name"
            required
            tooltip="First Name is required"
          >
            <Input placeholder="Enter you First Name" />
          </Form.Item>

          <Form.Item
            name={"lastName"}
            label="Last Name"
            required
            tooltip="Last Name is required"
          >
            <Input placeholder="Enter you Last Name" />
          </Form.Item>

          <Form.Item
            name={"fatherName"}
            label="Father Name"
            required
            tooltip="Father Name is required"
          >
            <Input placeholder="Enter you Father Name" />
          </Form.Item>

          <Form.Item
            name={"motherName"}
            label="Mother Name"
            required
            tooltip="Mother Name is required"
          >
            <Input placeholder="Enter you Mother Name" />
          </Form.Item>

          <Form.Item
            name={"aadharNumber"}
            label="Aadhar Number"
            required
            tooltip="Aadhar Number is required"
          >
            <Input placeholder="Enter you Aadhar Number" />
          </Form.Item>

          <Form.Item
            name={"contact"}
            label="Contact"
            required
            tooltip="Contact number is required"
          >
            <Input placeholder="Enter you Contact number" />
          </Form.Item>

          <Form.Item
            name={"email"}
            label="Email"
            required
            tooltip="Email is required"

          >
            <Input placeholder="Enter you email" type="email" />
          </Form.Item>

          <Form.Item
            name={"dob"}
            label="Date of Birth"
            required
            tooltip="Date of birth is required"

          >
            <DatePicker format={"DD/MM/YYYY"} disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            name={"collegeId"}
            label="College"
            required
            tooltip="College is required"
          >
            <Select
              // style={{ width: 120 }}
              defaultValue="Select"
            >
              {colleges.Items.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"type"}
            label="Type "
            required
            tooltip="User is required"
          >
            <Select
              // style={{ width: 120 }}
              defaultValue="Select"
            >
              {type.Items.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"user"}
            label="User "
            required
            tooltip="User is required"
          >
            <Select
              // style={{ width: 120 }}
              defaultValue="Select"
            >
              {user.Items.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.firstName} {item.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
         

          {/* <Form.Item
            name={"isFooter"}
            label="Is Footer"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
          </Form.Item> */}

          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default TrainingCreate;
