import React, { Fragment, useState, useEffect } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Table,
  Tag,
  Row,
  Col,
  Avatar,
  Popconfirm,
} from "antd";
import { AudioOutlined, DeleteOutlined, EditFilled, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link } from "react-router-dom";
import uiSettings from "../../utils/uiSettings";
import { useContentContext } from "../../context/ContentContext";
import GetImage from "../../utils/GetImage";

const { Title } = Typography;
const { Search } = Input;

const EventsTypeListing = () => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loadingId, setLoadingId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { Toast } = useContentContext();
  const [defaultState, setDefaultState] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [state, setState] = useState(defaultState);

  const columns: ColumnsType<any> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Avatar style={{ verticalAlign: "middle" }} size="large" gap={5}>
            <GetImage icon={record.thumbnail} />
          </Avatar>
          {text}
        </Space>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      width: 150,
    },
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/eventstype/${record._id}/edit` }} state={record}>
            <Button icon={<EditFilled />} />
          </Link>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button danger size="small" loading={loadingId === record._id}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async () => {
    try {
      setLoading(true);
      const apiRes = await seekSolutionApi.EventTypes.list();
      console.log("apiRes----->", apiRes);

      const newState = {
        Count: 0,
        ScannedCount: 0,
        Items: apiRes.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
        })),
      };
      setState(newState);
      setDefaultState(newState);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setLoading(true);
      setLoadingId(_id);
      console.log('_id', _id);
      const apiRes = await seekSolutionApi.EventTypes.delete(_id);
      console.log('api --->', apiRes);

      if (apiRes) {
        console.log("deleted id", apiRes);
        initialise();
        Toast.openSuccessNotification("Item Deleted Successfully");
      }
    } catch (error) {
      console.error("Error deleting item", error);
    } finally {
      setLoading(false);
      setLoadingId(null);
    }
  };

  useEffect(() => {
    initialise();
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredItems = defaultState.Items.filter((item: any) =>
      item.title.toLowerCase().startsWith(value.toLowerCase())
    );
    setState((prevState) => ({ ...prevState, Items: filteredItems }));
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: "Events Type",
            },
          ]}
        />
        <Title level={2}>Events Type</Title>
        <Divider />
        <Row gutter={[2, 8]} justify={"space-between"} >
          <Col span={20}>
            <Search
              placeholder="input search text"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              enterButton="Search"
            />
          </Col>
          <Col span={4} className="flex">
          <Button type="primary" onClick={showDrawer} >
              Filter
            </Button>
            <Col>
          <Link to="/eventstype/create">
              <PlusCircleOutlined style={{ fontSize: "28px", marginLeft: "10px" }} />
            </Link>
          </Col>
          </Col>
          
        </Row>
        <Table
          columns={columns}
          dataSource={(state.Items || []).map((res: any, index) => ({
            ...res,
            key: index + 1,
            createdAt: moment(res.createdAt).format("MMM, DD YYYY"),
          }))}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default EventsTypeListing;
