import React, { Fragment } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  message,
  Tag,
  Row,
  Col,
  Form,
  Select,
  InputRef,
  InputNumber,
  Upload,
  DatePicker,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import LanguageCode from "../../utils/ISO_639-2.json";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import SeekSolutionS3Bucket from "../../utils/SeekSolutionS3Bucket";
import { getBase64 } from "../../utils/uiSettings";
import { ICategory } from "../../context/interfaces";
import dayjs from "dayjs";
const { Title } = Typography;

const ProgramCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const categoryInputRef = React.useRef<InputRef>(null);
  const topicInputRef = React.useRef<InputRef>(null);
  const technologyInputRef = React.useRef<InputRef>(null);
  const [imageUrl, setImageUrl] = React.useState<string>();

  const [collage, setCollage] = React.useState<{
    count: number;
    data: Array<ICategory>;
    text: string;
  }>({
    count: 0,
    data: [],
    text: "",
  });

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Programs.create({
        ...values,
      });
      console.log("apiRes", apiRes);
      localStorage.removeItem("programs_draft");
      form.resetFields();
      navigate(`/program/page/1`, {
        replace: true,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values:any) => {
    console.log("handle submit running",dayjs(values.fromDate).valueOf());
    const data = {...values, fromDate:dayjs(values.fromDate).valueOf(),toDate:dayjs(values.toDate).valueOf()}
    
    setLoading(true);
    localStorage.setItem("programs_draft", JSON.stringify(data));
    await initialise(data);
    setLoading(false);
  };

  React.useEffect(() => {}, []);
  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/program/page/1`}>Program</Link>,
            },
            {
              title: "Create",
            },
          ]}
        />
        <Title level={2}>Collage</Title>
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...JSON.parse(localStorage.getItem("programs_draft") as string),
          }}
          layout="vertical"
        >
          <Form.Item
            name={"title"}
            label="Title"
            required
            tooltip="Title is required"
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          <Form.Item
            name={"desc"}
            label="Description"
            required
            tooltip="Description is required"
          >
            <Input.TextArea placeholder="Enter Description" />
          </Form.Item>

          <Form.Item
            name={"fromDate"}
            label="Started from"
            required
            tooltip="Please choose Program starting date"
          >
          <DatePicker  />
          </Form.Item>
          <Form.Item
            name={"toDate"}
            label="To Date"
            required
            tooltip="Please choose Program end date"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={"regAmt"}
            label="Registration Amount"
            required
            tooltip="Registration Amount is required"
          >
            <InputNumber placeholder="1000" />
            {/* <Input inputMode="numeric" placeholder="Enter registration fee" /> */}
          </Form.Item>
          <Form.Item
            name={"totalFee"}
            label="Total fee"
            required
            tooltip="Total Fee is required"
          >
            <InputNumber placeholder="15000" />
            {/* <Input inputMode="numeric" placeholder="Enter total fee" /> */}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};
export default ProgramCreate;
