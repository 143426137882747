import Item from "antd/es/list/Item";
import _superagent from "superagent";
import SuperagentPromise from "superagent-promise";
const superagent = SuperagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_ROOT;
console.log("API_ROOT", API_ROOT);

const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;

let token: any = "";
const tokenPlugin = (req: any) => {
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url: string) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url: string) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url: string, body: any) =>
    superagent
      .put(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url: string, body: any) =>
    superagent
      .patch(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url: any, body: any) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .then(responseBody),
  file: (url: string, key: string, file: any) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .attach(key, file)
      .use(tokenPlugin)
      .then(responseBody),
};

const Auth = {
  list: () => requests.get(`category`),
  get: (id: string) => requests.get(`category/${id}`),
  login: (item: any) => requests.post(`signin`, item),
};

const Category = {
  list: () => requests.get(`categories`),
  getList: () => requests.get(`categories`),
  deletd: (id: string) => requests.del(`categories/${id}`),
  get: (id: string) => requests.get(`categories/${id}`),
  update: (id: string, value: string) =>
    requests.patch(`categories/${id}`, value),
  create: (item: any) => requests.post(`categories`, item),
};




const Topic = {
  // list: (category_id: string) => requests.get(`topics/${category_id}`),
  list: () => requests.get(`topics`),
  getList: () => requests.get(`topics`),
  delete: (id: string) => requests.del(`topics/${id}`),
  getData: () => requests.get(`topics`),
  get: (category_id: string, information: string) =>
    requests.get(`topics/${category_id}/${information}`),
  create: (item: any) => requests.post(`topics`, item),
  update: (id: string, items: any) => requests.patch(`topics/${id}`, items),
};

const Technology = {
  list: () => requests.get(`technologies`),
  get: (topic: string, information: string) =>
    requests.get(`technologies/${topic}/${information}`),
  delete: (id: string) => requests.del(`technologies/${id}`),
  getData: () => requests.get(`technologies`),
  create: (topic: string, item: any) =>
    requests.post(`technologies/${topic}`, item),

  createData: (item: any) => requests.post(`technologies`, item),
  update: (id: string, items: any) =>
    requests.patch(`technologies/${id}`, items),
};

const Events = {
  list: () => requests.get(`events`),
  get: (id: string) => requests.get(`events/${id}`),
  create: (item: any) => requests.post(`events`, item),
  delete : (id :any) => requests.del(`events/${id}`),
  update: (id: any, items: any) => requests.patch(`events/${id}`, items),
};

const EventTypes = {
  list: () => requests.get(`events/type`),
  get: (id: string) => requests.get(`events/type/${id}`),
  create: (item: any) => requests.post(`events/type`, item),
  delete : (id :string) => requests.del(`events/type/${id}`),
  update: (id: any, items: any) => requests.patch(`events/type/${id}`, items),
};

const Courses = {
  list: () => requests.get(`courses`),
  get: (id: string) => requests.get(`courses/${id}`),
  create: (items: any) => requests.post(`courses`, items),
  update: (id: any, items: any) => requests.patch(`courses/${id}`, items),
  delete: (slug: string) => requests.del(`courses/${slug}`),
  buy: (course_id: string) =>
    requests.post(`courses/create-payment-intent`, { course_id }),
};

const Sections = {
  list: (courses_id: string) => requests.get(`sections/${courses_id}`),
  get: (courses_id: string) => requests.get(`sections/${courses_id}`),
  delete: (id: string) => requests.del(`sections/${id}`),
  update: (id: string, items: any) => requests.patch(`sections/${id}`, items),
  create: (course_id: string, items: any) =>
    requests.post(`sections/${course_id}`, items),
};

const Content = {
  list: () => requests.get(`contents`),
  get: () => requests.get(`contents`),
  create: (item: any) => requests.post(`contents`, item),
  update: (id: string, items: any) => requests.patch(`contents/${id}`, items),
  delete: (id: string) => requests.del(`contents/${id}`),
};

const ContactUs = {
  list: () => requests.get(`contact-us`),
  get: (id: string) => requests.get(`contact-us/${id}`),
  create: (item: any) => requests.post(`contact-us`, item),
  update: (id: string, data: any) => requests.patch(`contact-us/${id}`, data),
  delete: (id: string) => requests.del(`contact-us/${id}`),
};

const colleges = {
  list: () => requests.get(`colleges`),
  get: (id: string) => requests.get(`colleges/${id}`),
  create: (item: any) => requests.post(`colleges`, item),
  update: (id: string, items: any) => requests.patch(`colleges/${id}`, items),
  delete: (id: string) => requests.del(`colleges/${id}`),
};

const Programs = {
  list: () => requests.get(`programs`),
  get: (id: string) => requests.get(`programs/${id}`),
  create: (item: any) => requests.post(`programs`, item),
  update: (id: string, items: any) => requests.patch(`programs/${id}`, items),
  delete: (id: string) => requests.del(`programs/${id}`),
};

const Trainings = {
  list: () => requests.get(`trainings`),
  get: (id: string) => requests.get(`trainings/${id}`),
  create: (item: any) => requests.post(`trainings/summer-training`, item),
  update: (id: string, items: any) => requests.patch(`trainings/${id}`, items),
  delete: (id: string) => requests.del(`trainings/${id}`),
};

const Lectures = {
  // list: (section_id:string) => requests.get(`lectures/${section_id}`),
  list: (section_id: string) => requests.get(`lectures/${section_id}`),
  get: (section_id: string) => requests.get(`lectures/${section_id}`),
  // create: (item: any) => requests.post(`lectures`, item),
  update: (id: string, items: any) => requests.patch(`lectures/${id}`, items),
  delete: (id: string) => requests.del(`lectures/${id}`),
  create: (section_id: any, items: any) =>
    requests.post(`lectures/${section_id}`, items),
};

const Questions = {
  list: () => requests.get(`quizes/question`),
  get: (id: string) => requests.get(`quizes/question/${id}`),
  update: (id: string, items: any) =>
    requests.patch(`quizes/question/${id}`, items),
  delete: (id: string) => requests.del(`quizes/question/${id}`),
  create: ( items: any) =>
    requests.post(`quizes/question`, items),
};

const Users = {
  list: () => requests.get(`users`),
  get: (id: string) => requests.get(`users/${id}`),
  update: (id: string, items: any) =>
    requests.patch(`users/${id}`, items),
  delete: (id: string) => requests.del(`users/${id}`),
  create: ( items: any) =>
    requests.post(`users`, items),
};

const Playlists = {
  list: () => requests.get(`playlists`),
  get: (id: string) => requests.get(`playlists/${id}`),
  update: (id: string, items: any) =>
    requests.patch(`playlists/${id}`, items),
  delete: (id: string) => requests.del(`playlists/${id}`),
  create: ( items: any) =>
    requests.post(`playlists`, items),
};

const seekSolutionApi = {
  API_ROOT,
  Auth,
  Users,
  Category,
  Topic,
  Technology,
  Content,
  Events,
  ContactUs,
  colleges,
  Lectures,
  Courses,
  Sections,
  Trainings,
  Programs,
  Questions,
  Playlists,
  EventTypes,
  token,
  encode,
  setToken: (_token?: string) => {
    token = _token;
  },
};

export default seekSolutionApi;
