import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Space,
  Button,
  Table,
  Row,
  Col,
  Flex,
  Popconfirm,
} from "antd";
import { AudioOutlined, DeleteOutlined, EditFilled, UserAddOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import seekSolutionApi from "../../../../utils/seekSolutionApi";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
// const navigate = useNavigate();
const { Title } = Typography;
const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
    }}
  />
);

const LecturesListing = () => {
  const match = useMatch("/lectures/:section_id/page/:pagination");
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [loadingId, setLoadingId] = React.useState("");

  const [state, setState] = useState([]);

  const initialise = async () => {
    try {
      console.log("match?.params.section_id=>", match?.params.section_id);
      let apiRes = await seekSolutionApi.Lectures.list(match?.params.section_id as string);
      setState(apiRes.data);
    } catch (error) {
      console.error("Error fetching lectures:", error);
    }
  };

  const handleDelete = async (id: any) => {
    console.log("dlt id", id);

    try {
      setLoading(true);
      setLoadingId(id);
      let apiRes = await seekSolutionApi.Lectures.delete(id);
      console.log("deleted id1", apiRes);
      if (apiRes) {
        console.log("deleted id", apiRes);
        initialise();
        // window.alert("Item Deleted Successfully");
      }
    
    } catch (error) {
      console.error("Error deleting sections:", error);
    } finally {
      setLoading(false);
      
    }
  };

  useEffect(() => {
    initialise();
  }, [match?.params.section_id]); 

  const columns: ColumnsType<any> = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
  //   {
  //     title: 'Duration',
  //     dataIndex: 'banner',
  //     key: 'banner',
  //     render: (text) => (
  //         text
  //     ),
  // },
    {
      title: "Media Type",
      dataIndex: "mediaType",
      key: "mediaType",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space>
          <Link  to={{pathname:`/courses/${record.courses_id}/sections/${record._id}/lectures/${record.section_id}/edit`}} state={record}>
            <Button icon={<EditFilled />}></Button>
          </Link>
          

          <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                    console.log("thisis recofd id ",record._id);
                    
                  handleDelete(record._id);
                }}
              >
                <Button danger  loading={loadingId === record._id}>
                  <DeleteOutlined/>
                </Button>
              </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: "Courses" },
            { title: "Sections" },
            { title: match?.params.section_id },
            { title: "Lectures" },
          ]}
        />
 <Flex justify="space-between" gap={"small"} align="center" >
 <Title level={2}>Lectures</Title>
        <Link to={`/sections/${match?.params.section_id}/lectures/create`}>
          <Button  style={{marginRight:"10px"}}icon={<UserAddOutlined/>}></Button></Link>
          </Flex>

        <Divider />
        <Row gutter={[2, 8]} justify={"space-between"}>
          <Col span={20}>
            <Search
              placeholder="input search text"
              enterButton="Search"
              suffix={suffix}
            />
          </Col>
          <Col span={4}>
            {/* <Space>
              <Link to={`lectures/${'section_id'}/create`}>
                <Button type="primary">Add Lecture</Button>
              </Link>
            </Space> */}
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={state}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
    </Fragment>
  );
};

export default LecturesListing;
