import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  Upload,
  message,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContentContext } from "../../context/ContentContext";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
const { Title } = Typography;

const PlaylistEdit = () => {
  const params = useParams();
  const playlist_id = params.id || "";
  let { Toast } = useContentContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState("");
  const [btnType, setBtnType] = React.useState("save");
  const [open, setOpen] = React.useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const firebaseStorage = getStorage();
  // const answerOptions = [
  //   { value: "op1", label: "Option 1" },
  //   { value: "op2", label: "Option 2" },
  //   { value: "op3", label: "Option 3" },
  //   { value: "op4 ", label: "Option 4  " },
  // ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getData = async (question_id: string) => {
    try {

      let apiRes = await seekSolutionApi.Playlists.get(question_id);
      console.log("Get data --> ", apiRes);
      if (apiRes?._id) {
        const { title,url,author ,thumbnail} = apiRes;
        form.setFieldValue("title", title);
        form.setFieldValue("url", url);
        form.setFieldValue("author", author);
        form.setFieldValue("thumbnail", thumbnail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialise = async (values: any) => {
    console.log("valuedfghnm --->", values);

    try {

      if (!values.thumbnail || !values.thumbnail.file) {
        throw new Error("No thumbnail provided or file is missing");
      }
      const singleFile = values.thumbnail.file.originFileObj

      const storageRef = ref(firebaseStorage, 'Playlists/' + new Date().getTime() + singleFile.name);

      const uploaded = await uploadBytes(storageRef, singleFile)
      console.log("uploaded", uploaded.ref.fullPath);

      const downloadURL = await getDownloadURL(storageRef);
      setLoading(btnType);
      let apiRes = await seekSolutionApi.Playlists.update(playlist_id,  {
        ...values,
        thumbnail:uploaded.ref.fullPath});
      // console.log("apiRes", apiRes);
      // localStorage.removeItem("event_draft");
      form.resetFields();

      if (apiRes) {
        Toast.openSuccessNotification("Successfully Updated");
        console.log("ApiRes Categories -->", apiRes);
      }
      if (btnType == "saveExit") {
        console.log("Back");
        // history.back()
        window.history.back();
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading("");
    }
  };

  const handleSubmit = async (values: any) => {
    console.log("handleSubmit=>", values);

    const formData = {
      ...values,
    };

    // localStorage.setItem("event_draft", JSON.stringify(formData));

    await initialise(formData);
  };


  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }
    return true;
  };

  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj
      setImageUrl(URL.createObjectURL(obj))
    }
  };

  const getBase64 = (img: Blob, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  const initialvalues = {
    name: "",
  };

  useEffect(() => {
    getData(playlist_id);
  }, []);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/questions/page/1`}>PlayList Edit</Link>,
            },
            {
              title: "Update",
            },
          ]}
        />
        <Title level={2}>PlayList</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
           <Form.Item name="thumbnail" label="Thumbnail" required tooltip="This is a required field">
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*"
              fileList={fileList}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            name={"title"}
            label="Title"
            rules={[{ required: true, message: 'Title is required' }]}
          >
            <Input placeholder="Introduction to nodejs" />
          </Form.Item>

          <Form.Item
            name={"url"}
            label="Playlist URL"
            rules={[{ required: true, message: 'Playlist URL is required' }]}
          >
            <Input placeholder="https://youtube.com/playlist?list=PLRAV69dS1uWSjBBJ-egNNOd4mdblt1P4c&si=fNjl7lEfbAHnvoXi" />
          </Form.Item>

          <Form.Item
            name={"author"}
            label="Author"
            rules={[{ required: true, message: 'Author is required' }]}
          >
            <Input placeholder="Arvind Sir" />
          </Form.Item>

          <Space size="middle">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading == "save"}
              onMouseOver={() => setBtnType(() => "save")}
            >
              Update
            </Button>
            {/* <Button
              type="primary"
              htmlType="submit"
              loading={loading == "saveExit"}
              onMouseOver={() => setBtnType(() => "saveExit")}
            >
              Create and Exit
            </Button> */}
          </Space>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default PlaylistEdit;
