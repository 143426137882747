import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  DatePicker,
  TimePicker,
  UploadProps,
  message,
  Upload,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import LanguageCode from "../../utils/ISO_639-2.json";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useContentContext } from "../../context/ContentContext";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { IEVENT_TYPE } from "../../context/interfaces";
const { Title } = Typography;

const EventsTypeCreate = () => {
  const [eventTypesData, setEventTypesData] = useState<Array<{ label: string, value: string }>>([])
  const firebaseStorage = getStorage()
  let { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [btnType, setBtnType] = React.useState("save");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
    console.log("Values --->", values);

    try {
      
      
      setLoading(true);
      const apiRes = await seekSolutionApi.EventTypes.create(values);
      console.log("API Response:", apiRes);
      form.resetFields();
      navigate(-1);
      if (apiRes) {
        Toast.openSuccessNotification("Successfully Created");
        console.log("API Response Categories:", apiRes);
      }
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
  
    };
     console.log("values=>",values);
    await initialise(formData);
  };

  const initialvalues = {
    title: "",
  
    
  };
  console.log('initialvalues', initialvalues);




  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/eventstype/page/1`}>Events Type</Link>,
            },
            {
              title: "Create",
            },
          ]}
        />
        <Title level={2}>Events Type</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
          <Form.Item
            name={"title"}
            label="Title"
            required
            tooltip="Title is required"
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Space size="middle">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              onMouseOver={() => setBtnType(() => "save")}
            >
              Create
            </Button>

          </Space>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default EventsTypeCreate;
