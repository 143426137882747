import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  message,
  Upload,
} from "antd";
import seekSolutionApi from "../../../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate, useMatch } from "react-router-dom";
import moment from "moment";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const { Title } = Typography;

const LecturesEdit = () => {
  const { state } = useLocation();
  const match = useMatch("/courses/sections/:section_id/lectures/edit");
  const [bannerFile, setBannerFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const firebaseStorage = getStorage();
  console.log("state=>", state);

  const initialvalues = {
    title: state.title,
    mediaUrl: state.mediaUrl,
    mediaType: state.mediaType,
    icon: state.icon,
  };

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sections, setSections] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getSectionsData = async () => {
    try {
      let apiRes = await seekSolutionApi.Lectures.list(match?.params.section_id as string);
      if (apiRes) {
        console.log("apiRes get ->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            name: item.name,
            icon:item.icon,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setSections(newState);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getSectionsData();
    getSectionsList();
  }, []);

  const initialise = async (values: any) => {
    console.log('init form value ==', values);

    const { title, mediaUrl, mediaType,icon } = values;
    console.log('title ->', title);
    console.log('mediaUrl ->', mediaUrl);
    console.log('mediaType ->', mediaType);
    
    

    try {
      setLoading(true);
      console.log("this id ==>", state.id);

      let apiRes = await seekSolutionApi.Lectures.update(state._id, values);
      console.log("apiRes", apiRes);
      form.resetFields();

      if (apiRes) {
        console.log('apiRes Topic-->>', apiRes);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const beforeBannerUpload = (file: any) => {
    console.log("file.type", file.type);

    const isMp4OrPdf = file.type === "video/mp4" || file.type === "application/pdf";
    if (!isMp4OrPdf) {
      message.error("You can only upload mp4/pdf file!");
      return Upload.LIST_IGNORE;
    }

    const isLt20M = file.size / 1024 / 1024 < 200;
    if (!isLt20M) {
      message.error("File must be smaller than 20MB!");
      return Upload.LIST_IGNORE;
    }

    setBannerFile(file);
    return false; // Return false to prevent auto-upload
  };

  const getSectionsList = () => {
    console.log("Function running");
    let list: any = [];
    sections.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.title, banner:item.banner, });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  console.log("Dropdownlist", dropdownlist);

  const handleSubmit = async (values: any) => {
    if (!bannerFile) {
      message.error("Please upload a banner file");
      return;
    }
    setLoading(true);
    const storageRef = ref(firebaseStorage, `sections/${match?.params.section_id}/lectures/` + bannerFile);

    let uploaded;
    try {
      uploaded = await uploadBytes(storageRef, bannerFile);
    } catch (uploadError) {
      console.error("Error uploading file", uploadError);
      message.error("Failed to upload banner file");
      setLoading(false);
      return;
    }

    const downloadURL = await getDownloadURL(uploaded.ref);
    console.log('File available at', downloadURL);

    try {
      const body = {
        title: values.title,
        mediaUrl: downloadURL,
        duration: videoDuration,
       
         // Add duration to the payload
      };

      let apiRes = await seekSolutionApi.Lectures.update(state._id, body);
      if (!apiRes) {
        console.log("Invalid API response", apiRes);
        return;
      }

      message.success("Lecture updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error in handleSubmit", error);
      message.error("Failed to update lecture");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/lectures/page/1`}>Lecture</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Lecture Edit</Title>
      
        <Form.Item
          name="banner"
          required
          tooltip="This is a required field"
        >
       

          <Dragger
            accept="video/mp4,application/pdf"
            showUploadList={false}
            beforeUpload={beforeBannerUpload}
          >
            
            {bannerFile ? (
              <video
                src={URL.createObjectURL(bannerFile)}
                controls
                style={{ width: '100%' }}
                onLoadedMetadata={(e: any) => {
                  setVideoDuration(e.target.duration);
                }}
              />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                </p>
              </div>
            )}
          </Dragger>
          {bannerFile && (
            <div>
              <p>Video Duration: {videoDuration.toFixed(2)} seconds</p>
            </div>
          )}
        </Form.Item>

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
          <Form.Item
            name={"title"}
            label="title"
            required
            tooltip="title name is required"
          >
            <Input placeholder="Enter your title name" />
          </Form.Item>

          <Form.Item
            name={"mediaUrl"}
            label="mediaUrl"
            required
            tooltip="mediaUrl is required"
          >
            <Input placeholder="Enter your mediaUrl" />
          </Form.Item>
          <Form.Item
            name={"mediaType"}
            label="mediaType"
            required
            tooltip=" mediaType is required"
          >
            <Input placeholder="Enter your mediaType" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some Lecture...</p>
      </Drawer>
    </Fragment>
  );
};

export default LecturesEdit;
