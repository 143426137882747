import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  InputRef,
  
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ICategory, IEVENT_TYPE } from "../../context/interfaces";
import moment from "moment";
const { Title } = Typography;

const EventsTypeEdite = () => {
  const { state } = useLocation();
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [eventTypesData, setEventTypesData] = useState<Array<{ label: string; value: string }>>([]);

  const initialValues = {
    title: state.title,
  };

  console.log('initialValues->',initialValues);
  
  const onClose = () => setOpen(false);


  const initialise = async (values: any) => {
    setLoading(true);
    try {
      const apiRes = await seekSolutionApi.EventTypes.update(state.slug, values);

      console.log('state', state);

      form.resetFields();

      if (apiRes) {
        navigate(-1);
      }
    } catch (error:any) {
      if(error.response) {
       
        console.error("Server Error:", error.response.data);
      } else {
      
        console.error("Error updating event:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    console.log('values',values);
    const formData = {
      ...values,
    };
    console.log('formdata', formData);

    setLoading(true);
    localStorage.setItem("events_draft", JSON.stringify(values));
    await initialise(formData);
    setLoading(false);
  };


  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: <Link to={`/eventstype/page/1`}>EventsType</Link> },
            { title: "Edit" },
          ]}
        />
        <Title level={2}>Edit EventsType</Title>

        <Divider />
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item name="title" label="Title" required tooltip="Title is required">
            <Input placeholder="Enter the event title" />
          </Form.Item>
        
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>Apply</Button>
          </Space>
        }
      >
        <p>Some Events...</p>
      </Drawer>
    </Fragment>
  );
};

export default EventsTypeEdite;
