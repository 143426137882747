import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  DatePicker,
  TimePicker,
  UploadProps,
  message,
  Upload,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import LanguageCode from "../../utils/ISO_639-2.json";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useContentContext } from "../../context/ContentContext";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { IEVENT_TYPE } from "../../context/interfaces";
const { Title } = Typography;

const EventCreate = () => {
  const [eventTypesData, setEventTypesData] = useState<Array<{ label: string, value: string }>>([])
  const firebaseStorage = getStorage()
  let { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [btnType, setBtnType] = React.useState("save");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
    console.log("Values --->", values);

    try {
      if (!values.thumbnail) {
        return
      }
      const singleFile = values.thumbnail.file.originFileObj

      const storageRef = ref(firebaseStorage, 'events/' + new Date().getTime() + singleFile.name);

      const uploaded = await uploadBytes(storageRef, singleFile)
      console.log("uploaded", uploaded.ref.fullPath);
      // return 

      setLoading(true);
      const apiRes = await seekSolutionApi.Events.create({
        ...values,
        thumbnail: uploaded.ref.fullPath
      });
      console.log("API Response:", apiRes);
      form.resetFields();
      navigate(-1);


      if (apiRes) {
        Toast.openSuccessNotification("Successfully Created");
        console.log("API Response Categories:", apiRes);
      }

    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };




  const handleSubmit = async (values: any) => {
    let date = dayjs(values.date).toDate().getTime();
    const formData = {
      ...values,
      date,
      // thumbnail: values.thumbnail.fileList[0].name,
    };

    // console.log("values=>",values);


    await initialise(formData);
  };

  function onChange(info: any) {
    if (info.file.status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const initialvalues = {
    name: "",
    desc: "",
    date: "",
    time: "",
    link: "",
    thumbnail: "",
  };
  console.log('initialvalues', initialvalues);



  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  
  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj
      setImageUrl(URL.createObjectURL(obj))
    }
  };

  const getBase64 = (img: Blob, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const initialiseEventTypes = async () => {
    try {
      const apiRes = await seekSolutionApi.EventTypes.list()

      if (apiRes?.length > 0) {
        const selectData = apiRes?.map((item: IEVENT_TYPE) => {
          return {
            label: item.title,
            value: item.slug
          }
        })
        setEventTypesData(selectData)
      }

    } catch (error: any) {
      console.error("Error:", error);
    } finally {
    }
  };

  useEffect(() => {
    initialiseEventTypes()
  }, [])


  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/events/page/1`}>Events</Link>,
            },
            {
              title: "Create",
            },
          ]}
        />
        <Title level={2}>Events</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
          <Form.Item
            name={"title"}
            label="Title"
            required
            tooltip="Title is required"
          >
            <Input placeholder="React Webinar" />
          </Form.Item>

          <Form.Item
            name={"desc"}
            label="Event description"
            required
            tooltip="Event description is required"
          >
            <Input.TextArea placeholder="We are hosting React webinar... " />
          </Form.Item>

          <Form.Item
            name={"date"}
            label="Select event date & time"
            required
            tooltip="Date is required"
          >
            <DatePicker
              style={{ width: "100%" }}
              format={"YYYY-MM-DD hh:mm a"}
              showTime={{ format: "HH:mm" }}
              placeholder="Date & Time"
            />
          </Form.Item>

          <Form.Item
            name={"eventType"}
            label="Event Type"
            required
            tooltip="Event Type is required"
          >
            <Select
              placeholder={"Select Event Type"}
              // style={{ width: 120 }}
              options={eventTypesData}
            />
          </Form.Item>

          <Form.Item
            name={"link"}
            label="Event Link"
            tooltip="https://meet.google.com/uey-ydmb-eaj"
          >
            <Input placeholder="https://meet.google.com/uey-ydmb-eaj" />
          </Form.Item>

          <Form.Item
            name={"thumbnail"}
            label="Thumbnail"
            rules={[
              {
                required: true,
                message: "Please upload image thumbnail",
              },
            ]}
            valuePropName=" fileList "
          >
           
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              // accept="image/*"
              action={"#"}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}

            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Space size="middle">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              onMouseOver={() => setBtnType(() => "save")}
            >
              Create
            </Button>

          </Space>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default EventCreate;
