import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link } from "react-router-dom";
import moment from "moment";
import { useContentContext } from "../../context/ContentContext";

const { Title } = Typography;

const TopicCreate = () => {
  const { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getCategoriesData = async () => {
    try {
      const apiRes = await seekSolutionApi.Category.getList();
      console.log("apiRes--apiRes--apiRes", apiRes);
      
      if (apiRes) {
        const newState = {
          Count: apiRes.data.length,
          ScannedCount: apiRes.data.length,
          Items: apiRes.data.map((item:any) => ({
            id: item._id,
            name: item.name,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setCategories(newState);
      }
    } catch (error) {
      console.error("Error fetching categories data", error);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    getCategoriesList();
  }, [categories]);

  const initialise = async (values:any) => {
    console.log('init form value ==', values);
    
    try {
      setLoading(true);
      const apiRes = await seekSolutionApi.Topic.create(values);
      form.resetFields();

      if (apiRes) {
        console.log('apiRes Topic-->>', apiRes);
        Toast.openSuccessNotification("Successfully Created");
      }
    } catch (error) {
      console.error("Error creating topic", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values:any) => {
    const formData = { ...values };
    await initialise(formData);
  };

  const initialValues = {
    name: "",
  };

  const getCategoriesList = () => {
    console.log("Function running");
    let list: any = [];
    categories.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.name });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: <Link to={`/topic/page/1`}>Topic</Link> },
            { title: "Create" },
          ]}
        />
        <Title level={2}>Topic</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Category is required" }]}
          >
            <Select style={{ width: 120 }} placeholder="Select">
              {categories.Items.map((item:any) => (
                <Select.Option key={item.id} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input placeholder="Development" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default TopicCreate;
