import React, { Fragment } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Table,
  Tag,
  Row,
  Col,
  Avatar,
  Popconfirm,
  Flex,
} from "antd";
import { AudioOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link } from "react-router-dom";
import uiSettings from "../../utils/uiSettings";
import { ICourse } from "../../context/interfaces";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import GetImage from "../../utils/GetImage";
const { Title } = Typography;
const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
    }}
  />
);

const CoursesListing = () => {
  const [state, setState] = React.useState<{
    count: number;
    data: Array<ICourse>;
    filteredData: Array<ICourse>;
  }>({
    count: 0,
    data: [],
    filteredData: [],  // Adding filteredData to state
  });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingId, setLoadingId] = React.useState("");
  const storage = getStorage();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async () => {
    try {
      let apiRes = await seekSolutionApi.Courses.list();
      console.log("apiRes", apiRes);

      setState({
        ...state,
        data: apiRes.data,
        filteredData: apiRes.data,  // Initialize filteredData with fetched data
      });
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  React.useEffect(() => {
    initialise();
  }, []);

  // Search handler function
  const handleSearch = (value: string) => {
    const filteredData = state.data.filter(course =>
      course.title.toLowerCase().includes(value.toLowerCase()) || // Search by title
      (course.category?.name || "").toLowerCase().includes(value.toLowerCase()) // Search by category
    );
    setState({
      ...state,
      filteredData,  // Update filteredData with search results
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: 450,
      render: (text: string, record: any) => (
        <Link to={`/courses/${record?._id}/sections/page/1`}>
          <Space size="middle">
            <Avatar style={{ verticalAlign: "middle" }} size="large" gap={5}>
              <GetImage icon={record.icon}/>
            </Avatar>
            {text}
          </Space>
        </Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Rating",
      dataIndex: "rate",
      key: "rate",
      width: 150,
    },
     {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 40,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 40,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Link
            to={`/courses/${record?._id}/sections/page/1`}
          >
            <Button size="small">View</Button>
          </Link>
          <Link to={{ pathname: `/courses/${record._id}/edit` }} state={record}>
            <Button danger size="small">
              <EditOutlined/>
            </Button>
          </Link>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              handleDelete(record.slug);
              console.log("Delete ID", record);
            }}
          >
            <Button danger size="small" loading={loadingId === record.slug}>
              <DeleteOutlined/>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // DELETE handler
  const handleDelete = async (slug: any) => {
    console.log("Delete slug:", slug);
    try {
      setLoading(true);
      setLoadingId(slug);
      let apiRes = await seekSolutionApi.Courses.delete(slug);
      console.log("Deleted:", apiRes);
      if (apiRes) {
        initialise();
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: "Courses",
            },
          ]}
        />
        <Flex justify="space-between" gap={"small"} align="center">
          <Title level={2}>Courses</Title>
          <Link to={"/courses/create"}>
            <Button icon={<UserAddOutlined />}></Button>
          </Link>
        </Flex>
        <Divider />
        <Row gutter={[2, 8]} justify={"space-between"}>
          <Col span={20}>
            <Search
              placeholder="Search by title or category"
              enterButton="Search"
              suffix={suffix}
              onSearch={handleSearch}  // Add onSearch handler
            />
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={showDrawer}>
              Filter
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={state.filteredData.map((res, index) => ({
            ...res,
            key: index + 1,
            name: res.title,
            category: uiSettings.capitalizeFirstLetter(res?.category?.name || ""),
            ratings: res.rate,
            createdBy: `${res?.createdBy?.firstName || ""} ${
              res?.createdBy?.lastName || ""
            }`.trim() || "Not available",
            createdAt: moment(res.createdAt).format("MMM, DD YYYY"),
          }))}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default CoursesListing;
