import React, { Fragment } from "react";
import {
  Breadcrumb,
  Typography,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  InputRef,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useNavigate } from "react-router-dom";
import { ICategory } from "../../context/interfaces";
const { Title } = Typography;

const ContactCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
//   const categoryInputRef = React.useRef<InputRef>(null);
//   const topicInputRef = React.useRef<InputRef>(null);
//   const technologyInputRef = React.useRef<InputRef>(null);
//   const [imageUrl, setImageUrl] = React.useState<string>();

//   const [collage, setCollage] = React.useState<{count:number,data:Array<ICategory>,text:string}>({
//     count: 0,
//     data: [],
//     text: "",
//   });
  

 

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
   
    try {
        setLoading(true);
        let apiRes = await seekSolutionApi.ContactUs.create({
          ...values,
          // banner: bucketKey,
        });
        console.log("apiRes", apiRes);
        localStorage.removeItem("courses_draft");
        form.resetFields();
        navigate(-1);
      } catch (error) {
      } finally {
        setLoading(false);
      }
      
  };

  const handleSubmit = async (values: any) => {
    console.log("handle submit running");
    setLoading(true);
    localStorage.setItem("courses_draft", JSON.stringify(values));
    await initialise(values);
    setLoading(false);
  };

  React.useEffect(() => {
  
  }, []);
  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/contact/page/1`}>Contact</Link>,
            },
            {
              title: "Create",
            },
          ]}
        />
        <Title level={2}>Contact</Title>
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...JSON.parse(localStorage.getItem("courses_draft") as string),
          }}
          layout="vertical"
        >
        <Form.Item
        name={"firstName"}
        label="FirstName"
        required
        tooltip="This is a required field"
      >
        <Input placeholder="Enter your firstName" />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        label="LastName"
        required
        tooltip="code is required"
      >
         <Input placeholder="Enter your lastName" />
      </Form.Item>
      <Form.Item
        name={"phone"}
        label="Phone"
        required
        tooltip="This is a required field"
      >
        <Input placeholder="Enter your phone" />
      </Form.Item>
      <Form.Item
        name={"email"}
        label="Email"
        required
        tooltip="This is a required field"
      >
        <Input  placeholder="Enter your email" />
      </Form.Item>
      <Form.Item
        name={"description"}
        label="Description"
        required
        tooltip="This is a required field"
      >
        <Input   placeholder="Enter your description" />
      </Form.Item>
     
      <Button type="primary" htmlType="submit" loading={loading}>
        Create
      </Button>
    </Form>
  </Space>
  <Drawer
    title="Filter"
    placement={"right"}
    width={500}
    onClose={onClose}
    open={open}
    extra={
      <Space>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={onClose}>
          Apply
        </Button>
      </Space>
    }
  >
    <p>Some Contacts...</p>
  </Drawer>
</Fragment>

  );
};
export default ContactCreate;
