import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  DatePicker,
  InputNumber,
 
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
const { Title } = Typography;

const ProgramEdit = () => {
  const { state } = useLocation();

  console.log("state=>", state);
  
  const initialvalues = {
    title: state.title,
    desc: state.desc,
    fromDate:dayjs(state.fromDate),
    toDate:dayjs(state.toDate),
    regAmt:state.regAmt,
    totalFee:state.totalFee,

  };
  const navigate = useNavigate()


  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const initialise = async (values: any) => {
    const data = {...values, fromDate:dayjs(values.fromDate).valueOf(),toDate:dayjs(values.toDate).valueOf()}
    console.log("data=>",data);

    try {
      setLoading(true);
      console.log("this id",state.id);
      
      let apiRes = await seekSolutionApi.Programs.update(state._id, data);
      console.log("apiRes", apiRes);
      form.resetFields();

      if (apiRes) {
        console.log('apiRes Topic-->>', apiRes);
        navigate(`/program/page/1`)
        // window.alert("Successfully Updated");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
    };
    await initialise(formData);
  };
 
  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/program/page/1`}>Program</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Program</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
         

         <Form.Item
            name={"title"}
            label="Title"
            required
            tooltip="Title is required"
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          <Form.Item
            name={"desc"}
            label="Description"
            required
            tooltip="Description is required"
          >
            <Input.TextArea placeholder="Enter Description" />
          </Form.Item>

          <Form.Item
            name={"fromDate"}
            label="Started from"
            required
            tooltip="Please choose Program starting date"
          >
          <DatePicker  />
          </Form.Item>
          <Form.Item
            name={"toDate"}
            label="To Date"
            required
            tooltip="Please choose Program end date"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={"regAmt"}
            label="Registration Amount"
            required
            tooltip="Registration Amount is required"
          >
            <InputNumber placeholder="1000" />
            {/* <Input inputMode="numeric" placeholder="Enter registration fee" /> */}
          </Form.Item>
          <Form.Item
            name={"totalFee"}
            label="Total fee"
            required
            tooltip="Total Fee is required"
          >
            <InputNumber placeholder="15000" />
            {/* <Input inputMode="numeric" placeholder="Enter total fee" /> */}
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      
    </Fragment>
  );
};

export default ProgramEdit;
