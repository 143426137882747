import React, { Fragment, useState, useEffect } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { useContentContext } from "../../context/ContentContext";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Search } = Input;

const TrainingListing = () => {
  const { Toast } = useContentContext();
  const [defaultState, setDefaultState] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [state, setState] = useState({ ...defaultState });
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [open, setOpen] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Father Name",
      dataIndex: "fatherName",
      key: "fatherName",
    },
    {
      title: "Mother Name",
      dataIndex: "motherName",
      key: "motherName",
    },
    {
      title: "Aadhar Number",
      dataIndex: "aadharNumber",
      key: "aadharNumber",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/trainings/${record.id}/edit` }} state={record}>
            <Button danger size="small">
              <EditOutlined />
            </Button>
          </Link>

          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <Button danger size="small" loading={loadingId === record.id}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async () => {
    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Trainings.list();
      const newState = {
        Count: apiRes.length,
        ScannedCount: apiRes.length,
        Items: apiRes.map((item: any) => ({
          ...item,
          id: item._id,
          dob: moment(item.dob).format("MMM, DD YYYY"),
          createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
        })),
      };
      setState(newState);
      setDefaultState(newState);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_id: any) => {
    try {
      setLoadingId(_id);
      let apiRes = await seekSolutionApi.Trainings.delete(_id);
      if (apiRes) {
        initialise();
        Toast.openSuccessNotification("Item Deleted Successfully");
      }
    } catch (error: any) {
      console.error("Error deleting category:", error.message);
      const msg = JSON.parse(error.message);
      Toast.openErrorNotification(msg.message);
    } finally {
      setLoadingId("");
    }
  };

  useEffect(() => {
    initialise();
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredItems = defaultState.Items.filter((item: any) =>
      item.firstName?.toLowerCase().includes(value.toLowerCase())
    );
    setState((prevState) => ({ ...prevState, Items: filteredItems }));
  };

  const resetSearch = () => {
    setSearchValue("");
    setState({ ...defaultState });
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: "trainings",
            },
          ]}
        />
        <Title level={2}>Trainings</Title>
        <Divider />
        <Row gutter={[2, 8]} justify={"space-between"}>
          <Col span={20}>
            <Search
              placeholder="Search by First Name"
              enterButton="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={handleSearch} // Update to trigger search
            />
          </Col>
          <Col
            span={4}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <Button type="primary" onClick={showDrawer}>
              Filter
            </Button>
            <Button onClick={resetSearch}>Reset</Button>
            <a href="/trainings/create">
              <PlusCircleOutlined style={{ fontSize: "28px" }} />
            </a>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={state.Items.map((res: any, index) => ({
            ...res,
            key: index + 1,
            name: res.name,
            createdAt: moment(res.createdAt).format("MMM, DD YYYY"),
          }))}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default TrainingListing;
