import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard, Login, Map, NotFound } from "./pages";
import { Main } from "./layouts";
import CoursesListing from "./pages/courses/CoursesListing";
import { useGlobalProvider } from "./context/Provider";
import EventLayout from "./pages/events/EventLayout";
import EventListing from "./pages/events/EventListing";
import EventCreate from "./pages/events/EventCreate";
import CoursesContentListing from "./pages/courses/content/SectionListing";
import ContactUsListing from "./pages/contact-us/ContactUsListing";
import CategoryLayout from "./pages/category/CategoryLayout";
import CategoryListing from "./pages/category/CategoryListing";
import CategoryCreate from "./pages/category/CategoryCreate";
import TopicLayout from "./pages/topic/TopicLayout";
import TopicCreate from "./pages/topic/TopicCreate";
import TopicListing from "./pages/topic/TopicListing";
import TechnologyLayout from "./pages/technology/TechnologyLayout";
import TechnologyListing from "./pages/technology/TechnologyListing";
import TechnologyCreate from "./pages/technology/TechnologyCreate";
import ContentListing from "./pages/content/ContentListing";
import CategoryEdit from "./pages/category/CategoryEdit";
import ContentCreate from "./pages/content/ContentCreate";
import ContentEdit from "./pages/content/ContentEdit";

import CollageListing from "./pages/colleges/CollageListing";
import CollageEdit from "./pages/colleges/CollageEdit";
import CoursesLayout from "./pages/courses/CoursesLayout";
import CoursesDetails from "./pages/courses/CoursesDetails";
import CoursesCreate from "./pages/courses/CoursesCreate";

import CreateSection from "./pages/courses/content/CreateSection";
import LecturesListing from "./pages/courses/content/lectures/LecturesListing";
import LecturesEdit from "./pages/courses/content/lectures/LecturesEdit";
import CoursesEdit from "./pages/courses/CoursesEdit";
import SectionEdite from "./pages/courses/content/SectionEdite";
import LecturesCreate from "./pages/courses/content/lectures/LecturesCreate";
import TrainingListing from "./pages/training/TrainingListing";
import TrainingCreate from "./pages/training/TrainingCreate";
import TrainingEdit from "./pages/training/TrainingEdit";
import CollageCreate from "./pages/colleges/CollageCreate";
import TopicEdit from "./pages/topic/TopicEdit";
import TechnologyEdit from "./pages/technology/TechnologyEdit";
import ContactCreate from "./pages/contact-us/ContactCreate";
import ProgramListing from "./pages/programs/ProgramListing";
import ProgramEdit from "./pages/programs/ProgramEdit";
import ProgramCreate from "./pages/programs/ProgramCreate";
import QuestionListing from "./pages/questions/QuestionListing";
import QuestionCreate from "./pages/questions/QuestionCreate";
import QuestionEdit from "./pages/questions/QuestionEdit";
import PlaylistListing from "./pages/playlists/PlaylistListing";
import PlaylistCreate from "./pages/playlists/PlaylistCreate";
import PlaylistEdit from "./pages/playlists/PlaylistEdit";
import EventsEdit from "./pages/events/EventsEdite";
import EventsTypeListing from "./pages/eventstype/EventsTypeListing";
import EventsTypeCreate from "./pages/eventstype/EventsTypeCreate";
import EventsTypeEdite from "./pages/eventstype/EventsTypeEdite";


function App() {
  const { authState } = useGlobalProvider();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {authState?.accessToken ? (
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/map" element={<Map />} />
          <Route path="/completion" element={<Map />} />

          {/* Contents  */}
          <Route path="/content/page/1" element={<ContentListing />} />
          <Route path="/content/create" element={<ContentCreate />} />
          <Route path="/content/:id/edit" element={<ContentEdit />} />

          {/* Category */}
          <Route path="/category/:id/edit" element={<CategoryEdit />} />
          <Route path="/category/create" element={<CategoryCreate />} />

          {/* Trainings  */}
          <Route path="/trainings/page/1" element={<TrainingListing />} />
          <Route path="/trainings/create" element={<TrainingCreate />} />
          <Route path="/trainings/:id/edit" element={<TrainingEdit />} />



          {/* courses */}

          <Route path="/courses" element={<CoursesLayout />}>
            <Route
              path="/courses/:courses_id/view"
              element={<CoursesDetails />}
            />
            <Route
              path="/courses/:courses_id/sections/page/:pagination"
              element={<CoursesContentListing />}
            />

            <Route
              path="/courses/:courses_id/sections/create"
              element={<CreateSection />}
            />
           
           <Route path="/courses/:courses_id/sections/:section_id/edit" element={<SectionEdite />} />

            <Route
              path="/courses/page/:pagination"
              element={<CoursesListing />}
            />
            <Route path="/courses/:id/edit" element={<CoursesEdit />} />

            <Route path="/courses/create" element={<CoursesCreate />} />
          </Route>


          <Route path="/lectures/:section_id/page/:pagination" element={<LecturesListing />} />
          {/* <Route path="/courses/:courses_id/sections/:sections_id/lectures/:section_id/page/:pagination" element={<LecturesListing />} /> */}


          
          <Route path="/courses/:courses_id/sections/:section_id/lectures/:section_id/edit" element={<LecturesEdit/>}/>
      
          <Route
              path="/sections/:section_id/lectures/create"
              element={<LecturesCreate/>}
            />

{/* 
          <Route path="/events" element={<EventLayout />}>
            <Route path="/events/page/:pagination" element={<EventListing />} />
            <Route path="/events/create" element={<EventCreate />} />
          </Route> */}
          
          <Route path="/category" element={<CategoryLayout />}>
            <Route
              path="/category/page/:pagination"element={<CategoryListing />}
            />
            <Route path="/category/create" element={<CategoryCreate />} />
          </Route>
          <Route path="/topic" element={<TopicLayout />}>
            <Route path="/topic/page/:pagination" element={<TopicListing />} />
            <Route path="/topic/create" element={<TopicCreate />} />
            <Route path="/topic/:id/edit" element={<TopicEdit />} />
          </Route>
      
          <Route path="/technology" element={<TechnologyLayout />}>
            <Route
              path="/technology/page/:pagination"
              element={<TechnologyListing />}
            />
            <Route path="/technology/create" element={<TechnologyCreate />} />
            <Route path="/technology/:id/edit" element={<TechnologyEdit />} />
          </Route>

          <Route
            path="/contact-us/page/:pagination"
            // element={<ContactUsListing />}
            element={<ContactUsListing />}
          />
          <Route
            path="/contact/create"
            // element={<ContactUsListing />}
            element={<ContactCreate />}
          />
          <Route
            path="colleges/page/:pagination"
            element={<CollageListing />}
          // element={<ContactUsListing />}
          />
          <Route path="/college/:id/edit" element={<CollageEdit />} />
          <Route path="college/create" element={<CollageCreate />} />

          <Route
            path="program/page/:pagination"
            element={<ProgramListing />}
          />
          <Route path="/program/:id/edit" element={<ProgramEdit />} />
          <Route path="program/create" element={<ProgramCreate />} />

          <Route
            path="/contants/page/:pagination"
            // element={<ContactUsListing />}
            element={<ContentListing />}
          />

          {/* Questions */}
          <Route path="/questions/page/1" element={<QuestionListing />} />
          <Route path="/questions/create" element={<QuestionCreate />} />
          <Route path="/questions/:id/edit" element={<QuestionEdit />} />

          {/* Playlists */}
          <Route path="/playlists/page/1" element={<PlaylistListing />} />
          <Route path="/playlists/create" element={<PlaylistCreate />} />
          <Route path="/playlists/:id/edit" element={<PlaylistEdit />} />
          
        {/* Event */}

        <Route path="/events/page/1" element={<EventListing/>}/>
        <Route path="/events/create" element={<EventCreate/>}/>
        <Route path="/events/:id/edit" element={<EventsEdit/>}/>
       
        {/* Events Tyep */}
        <Route path="/eventstype/page/1" element={<EventsTypeListing/>}/>
        <Route path="/eventstype/create" element={<EventsTypeCreate/>}/>
        <Route path="/eventstype/:id/edit" element={<EventsTypeEdite/>}/>

        </Route>
      ) : (
        <>
          <Route path="*" element={<NotFound />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
      {/* <Route path="/500" element={<SomethingWentWrong />} /> */}
    </Routes>
  );
}

export default App;
