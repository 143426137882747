import React, { Fragment, useState, useEffect } from 'react';
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import seekSolutionApi from '../../utils/seekSolutionApi';
import { useContentContext } from '../../context/ContentContext';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useGlobalProvider } from '../../context/Provider';
import uiSettings from '../../utils/uiSettings';

const { Title } = Typography;
const { Search } = Input;

const ContentListing = () => {
  const [defaultState, setDefaultState] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const { authState } = useGlobalProvider();
  const { Toast } = useContentContext();

  const [state, setState] = useState({ ...defaultState });
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState('');
  const [open, setOpen] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Is Footer',
      dataIndex: 'isFooter',
      key: 'isFooter',
      render: (value: boolean) => <>{value.toString()}</>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record: any) =>
        authState._id === record.createdBy && (
          <Space size="middle">
            <Link to={`/content/${record._id}/edit`} state={record}>
              <Button type="primary" size="small">
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title="Delete the item"
              onConfirm={() => handleDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger size="small" loading={loadingId === record._id}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async () => {
    try {
      setLoading(true);
      const apiRes = await seekSolutionApi.Content.list();
      const newState = {
        Count: apiRes.length,
        ScannedCount: apiRes.length,
        Items: apiRes.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format('MMM, DD YYYY'),
        })),
      };
      setState(newState);
      setDefaultState(newState);
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setLoadingId(_id);
      const apiRes = await seekSolutionApi.Content.delete(_id);
      if (apiRes) {
        initialise();
        Toast.openSuccessNotification('Item Deleted Successfully');
      }
    } catch (error: any) {
      console.error('Error deleting item:', error.message);
      const msg = JSON.parse(error.message);
      Toast.openErrorNotification(msg.message);
    } finally {
      setLoadingId('');
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredItems = defaultState.Items.filter((item: any) =>
      ['title', 'desc', 'slug'].some((key) =>
        item[key]?.toLowerCase().includes(value.toLowerCase())
      )
    );
    setState((prevState) => ({ ...prevState, Items: filteredItems }));
  };

  const resetSearch = () => {
    setSearchValue('');
    setState(defaultState);
  };

  useEffect(() => {
    initialise();
  }, []);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Breadcrumb
          items={[
            {
              title: 'Management',
            },
            {
              title: 'Content',
            },
          ]}
        />
        <Title level={2}>Contents</Title>
        <Divider />
        <Row gutter={[2, 8]} justify="space-between">
          <Col span={20}>
            <Search
              placeholder="Input search text"
              enterButton="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={handleSearch}
            />
          </Col>
          <Col span={4} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <Button type="primary" onClick={showDrawer}>
              Filter
            </Button>
            <Button onClick={resetSearch}>Reset</Button>
            <Link to="/content/create">
              <PlusCircleOutlined style={{ fontSize: '28px' }} />
            </Link>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={state.Items.map((res: any, index) => ({
            ...res,
            key: index + 1,
            desc: uiSettings.capitalizeFirstLetter(res.desc),
            createdAt: moment(res.createdAt).format('MMM, DD YYYY'),
          }))}
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
        />
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default ContentListing;
