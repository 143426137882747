import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Input,
  Space,
  Button,
  Form,
  Row,
  Col,
  message,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import seekSolutionApi from "../../../../utils/seekSolutionApi";


const { Title } = Typography;
const { Dragger } = Upload;

const LecturesCreate = () => {
  const { section_id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bannerFile, setBannerFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const firebaseStorage = getStorage();

  const beforeBannerUpload = (file: any) => {
    console.log("file.type", file.type);
  
    const isMp4OrPdf = file.type === "video/mp4" || file.type === "application/pdf";
    if (!isMp4OrPdf) {
      message.error("You can only upload mp4/pdf file!");
      return Upload.LIST_IGNORE;
    }
  
    const isLt20M = file.size / 1024 / 1024 < 200;
    if (!isLt20M) {
      message.error("File must be smaller than 20MB!");
      return Upload.LIST_IGNORE;
    }
  
    setBannerFile(file);
    return false; // Return false to prevent auto-upload
  };


  const handleSubmit = async (values: any) => {
    console.log("handle submit running");
    localStorage.setItem("courses_draft", JSON.stringify(values));
  
    if (!bannerFile) {
      message.error("Please upload a banner file");
      return;
    }
    setLoading(true)
    const storageRef = ref(
      firebaseStorage,
    //   `sections/${section_id}/lectures/` + bannerFile.name
    `sections/${section_id}/lectures/` + bannerFile
    );
  
    let uploaded;
    try {
      uploaded = await uploadBytes(storageRef, bannerFile);
    } catch (uploadError) {
      console.error("Error uploading file", uploadError);
      message.error("Failed to upload banner file");
      return;
    }
  
    try {
      if (!section_id) {
        console.error("Section ID is undefined");
        return;
      }
  
      const body = {
        title: values.title,
        mediaUrl: uploaded.ref.fullPath,
        // mediaType: bannerFile.type,
        // mediaType: bannerFile,
        duration: videoDuration, // Add duration to the payload
      };
  
      let apiRes = await seekSolutionApi.Lectures.create(section_id, body);
      window.history.back()
      if (!apiRes) {
        console.log("Invalid API response", apiRes);
        return;
      }
  
      message.success("Lecture created successfully!");
    
    } catch (error) {
      console.error("Error in handleSubmit", error);
      message.error("Failed to create lecture");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: "Courses" },
            { title: "Section" },
            { title: section_id },
            { title: "Lecture" },
            { title: "Create" },
          ]}
        />
        <Title level={2}>Create Lecture</Title>

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...JSON.parse(localStorage.getItem("section_draft") as string),
          }}
          layout="vertical"
        >
          <Row gutter={[12, 8]} justify={"space-between"}>
            <Col span={16}>
              <Form.Item
                name="banner"
                label="Upload section video"
                required
                tooltip="This is a required field"
              >
                <Dragger
                  accept="video/mp4,application/pdf"
                  showUploadList={false}
                  beforeUpload={beforeBannerUpload}
                >
                  {bannerFile ? (
                    <video
                      src={URL.createObjectURL(bannerFile)}
                      controls
                      style={{ width: '100%' }}
                      onLoadedMetadata={(e:any) => {
                        setVideoDuration(e.target.duration);
                      }}
                    />
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                      </p>
                    </div>
                  )}
                </Dragger>
                {bannerFile && (
                  <div>
                    <p>Video Duration: {videoDuration.toFixed(2)} seconds</p>
                  </div>
                )}
              </Form.Item>

              <Form.Item
                name="title"
                label="Title"
                required
                tooltip="This is a required field"
              >
                <Input type="text" placeholder="Write your title" />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Create
              </Button>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
      </Space>
    </Fragment>
  );
};

export default LecturesCreate;
