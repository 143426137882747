import React, { Fragment, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Upload,
  message,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { useContentContext } from "../../context/ContentContext";
const { Title } = Typography;

const PlaylistCreate = () => {
  let { Toast } = useContentContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");
  const [btnType, setBtnType] = useState("save");
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const firebaseStorage = getStorage();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const initialise = async (values: any) => {
    try {
      if (values.thumbnail && values.thumbnail.file) {
        const singleFile = values.thumbnail.file.originFileObj;
        const storageRef = ref(firebaseStorage, 'Playlists/' + new Date().getTime() + singleFile.name);
        const uploaded = await uploadBytes(storageRef, singleFile);
        setLoading(btnType);
        let apiRes = await seekSolutionApi.Playlists.create({
          ...values,
          thumbnail: uploaded.ref.fullPath
        });
        form.resetFields();
        if (apiRes) {
          Toast.openSuccessNotification("Successfully Created");
          if (btnType == "saveExit") {
            console.log("Back");
           
            window.history.back();
          }
          navigate(-1);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading("");
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = { ...values };
    await initialise(formData);
  };

  
  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj
      setImageUrl(URL.createObjectURL(obj))
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const initialValues = {
    name: "",
    link: "",
    thumbnail: "",
  };

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            { title: "Management" },
            { title: <Link to={`/playlists/page/1`}>Playlists</Link> },
            { title: "Create" },
          ]}
        />
        <Title level={2}>Playlist</Title>
        <Divider />
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
            name={"thumbnail"}
            label="Thumbnail"
            rules={[
              { required: true, message: "Please upload image thumbnail" },
            ]}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*"
              action="#"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            name={"title"}
            label="Title"
            rules={[{ required: true, message: 'Title is required' }]}
          >
            <Input placeholder="Introduction to nodejs" />
          </Form.Item>
          <Form.Item
            name={"url"}
            label="Playlist URL"
            rules={[{ required: true, message: 'Playlist URL is required' }]}
          >
            <Input placeholder="https://youtube.com/playlist?list=PLRAV69dS1uWSjBBJ-egNNOd4mdblt1P4c&si=fNjl7lEfbAHnvoXi" />
          </Form.Item>
          <Form.Item
            name={"author"}
            label="Author"
            rules={[{ required: true, message: 'Author is required' }]}
          >
            <Input placeholder="Arvind Sir" />
          </Form.Item>
          <Space size="middle">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading === "save"}
              onMouseOver={() => setBtnType("save")}
            >
              Create
            </Button>
            {/* Uncomment if needed */}
            {/* <Button
              type="primary"
              htmlType="submit"
              loading={loading === "saveExit"}
              onMouseOver={() => setBtnType("saveExit")}
            >
              Create and Exit
            </Button> */}
          </Space>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>Apply</Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default PlaylistCreate;
