import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
} from "antd";
import seekSolutionApi from "../../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate,useMatch } from "react-router-dom";
import moment from "moment";
const { Title } = Typography;

const SectionEdite = () => {
  const { state } = useLocation();
  const match = useMatch(`/courses/:courses_id/sections/:section_id/edit`)
  // const match = useMatch("/courses/:state._id/sections/edit")
  // match?.params.courses_id as string

  console.log("section edit match=>",match);
  


  console.log("state======>", state);
  
  const initialValues = {
    title: state.title,
  };

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sections, setSections] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList]: any = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getSectionData = async () => {
    try {
        console.log("state.slug---------->",state.courses_id);
        
      let apiRes = await seekSolutionApi.Sections.list(match?.params.courses_id as string);
      if (apiRes) {
        console.log("apiRes get courses->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item:any) => ({
            id: item._id,
            title: item.title,
           
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setSections(newState);
      }
    } catch (error) {
      console.error("Error fetching courses data", error);
    }
  };

  useEffect(() => {
    // getSectionData();
    // getSectionList();
    }, []);

    const initialise = async (values: any) => {
      console.log('init form value ==', values);
      const { title, } = values
      console.log('title ->', title)
  
      try {
        setLoading(true);
        console.log("this id----",state._id);
        
        let apiRes = await seekSolutionApi.Sections.update(state._id, values);
        console.log("apiRes", apiRes);
        form.resetFields();
  
        if (apiRes) {
          console.log('apiRes sections-->>', apiRes);
          navigate(`/courses/${match?.params.courses_id}/sections/page/1`)
          // window.alert("Successfully Updated");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSubmit = async (values: any) => {
      const formData = {
        ...values,
      };
      await initialise(formData);
    };
  
  
  
    const getSectionList = () => {
      console.log("Function running");
      let list: any = [];
      sections.Items.map((item: any) => {
        console.log(item);
        list.push({ id: item.id, label: item.title });
      });
      console.log("list", list);
      setDropdownList(list);
    };



  // console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/sections/page/1`}>Section</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Edit Section</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="title"
            required
            tooltip="title is required"
          >
            <Input placeholder="Enter the course name" />
          </Form.Item>

        



          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some Section...</p>
      </Drawer>
    </Fragment>
  );
};

export default SectionEdite;
