import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
 
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useContentContext } from "../../context/ContentContext";
const { Title } = Typography;

const TechnologyEdit = () => {
  const { state } = useLocation();
  const initialvalues = {
    name: state.name,
  
  };
  const navigate = useNavigate()

  let { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [colleges, setColleges] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList]: any = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getTechnologyData = async () => {
    try {
      let apiRes = await seekSolutionApi.Technology.list();
      if (apiRes) {
        console.log("apiRes get colleges->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            name: item.name,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setColleges(newState);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getTechnologyData();
    getTechonologyList();
  }, []);

  const initialise = async (values: any) => {
    console.log('init form value ==', values);
    const { name,} = values
    console.log('name ->', name)
   
    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Technology.update(state.id, values);
      console.log("apiRes--->", apiRes);
      form.resetFields();

      if (apiRes) {
        navigate("/technology/page/1")
        Toast.openSuccessNotification("Successfully Updated");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
    //   name: moment(values.name).valueOf(),
    };

    console.log('formData', formData);

    await initialise(formData);
  };

  const getTechonologyList = () => {
    console.log("Function running");
    let list: any = [];
    colleges.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.name });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/technology/page/1`}>Technology</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Technology</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >
          <Form.Item
            name={"name"}
            label="Name"
            required
            tooltip="First Name is required"
          >
            <Input placeholder="Enter you First Name" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some Technologies...</p>
      </Drawer>
    </Fragment>
  );
};

export default TechnologyEdit;
