import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Select,
  DatePicker,
  TimePicker,
  UploadProps,
  message,
  Upload,
  Switch,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LanguageCode from "../../utils/ISO_639-2.json";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useContentContext } from "../../context/ContentContext";
import ReactQuill from "react-quill";
const { Title } = Typography;

const TrainingEdit = () => {
  const { state } = useLocation();
  const initialvalues = {
    firstName: state.firstName,
    lastName: state.lastName,
    fatherName: state.fatherName,
    motherName: state.motherName,
    aadharNumber: state.aadharNumber,
    dob: dayjs(state.dob),
    collegeId: state.college
  };
  const navigate = useNavigate()

  let { Toast } = useContentContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [colleges, setColleges] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList]: any = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getCollegesData = async () => {
    try {
      let apiRes = await seekSolutionApi.colleges.list();
      if (apiRes) {
        console.log("apiRes get colleges->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            name: item.name,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setColleges(newState);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getCollegesData();
    getTopicsList();
  }, []);

  const initialise = async (values: any) => {
    console.log('init form value ==', values);
    const { title, desc } = values
    console.log('Title ->', title)
    console.log('Description ->', desc)

    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Trainings.update(state.id, values);
      console.log("apiRes", apiRes);
      form.resetFields();

      if (apiRes) {
        navigate("/trainings/page/1")
        Toast.openSuccessNotification("Successfully Updated");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      ...values,
      dob: moment(values.dob).valueOf(),
    };

    console.log('Fvalues', formData);

    await initialise(formData);
  };

  const getTopicsList = () => {
    console.log("Function running");
    let list: any = [];
    colleges.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.name });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  console.log("Dropdownlist", dropdownlist);

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/training/page/1`}>Training</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Training</Title>
        <Divider />

        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialvalues}
          layout="vertical"
        >


          <Form.Item
            name={"firstName"}
            label="First Name"
            required
            tooltip="First Name is required"
          >
            <Input placeholder="Enter you First Name" />
          </Form.Item>

          <Form.Item
            name={"lastName"}
            label="Last Name"
            required
            tooltip="Last Name is required"
          >
            <Input placeholder="Enter you Last Name" />
          </Form.Item>

          <Form.Item
            name={"fatherName"}
            label="Father Name"
            required
            tooltip="Father Name is required"
          >
            <Input placeholder="Enter you Father Name" />
          </Form.Item>

          <Form.Item
            name={"motherName"}
            label="Mother Name"
            required
            tooltip="Mother Name is required"
          >
            <Input placeholder="Enter you Mother Name" />
          </Form.Item>

          <Form.Item
            name={"aadharNumber"}
            label="Aadhar Number"
            required
            tooltip="Aadhar Number is required"
          >
            <Input placeholder="Enter you Aadhar Number" />
          </Form.Item>

          {/* <Form.Item
            name={"contact"}
            label="Contact"
            required
            tooltip="Contact number is required"
          >
            <Input placeholder="Enter you Contact number" />
          </Form.Item>

          <Form.Item
            name={"email"}
            label="Email"
            required
            tooltip="Email is required"
          >
            <Input placeholder="Enter you email" type="email" />
          </Form.Item> */}

          <Form.Item
            name={"dob"}
            label="Date of Birth"
            required
            tooltip="Date of birth is required"

          >
            <DatePicker format={"DD/MM/YYYY"} disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            name={"collegeId"}
            label="College"
            required
            tooltip="College is required"
          >
            <Select
              // style={{ width: 120 }}
              defaultValue={initialvalues.collegeId}
            // defaultActiveFirstOption={true}
            >
              {colleges.Items.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name={"isFooter"}
            label="Is Footer"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
          </Form.Item> */}

          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some contents...</p>
      </Drawer>
    </Fragment>
  );
};

export default TrainingEdit;
