import React, { Fragment } from "react"
import { Breadcrumb, Typography, Divider, Input, Drawer, Space, Button, Table, Tag, Row, Col, Avatar, Popconfirm, Flex } from 'antd';
import { AudioOutlined, DeleteFilled, DeleteOutlined, EditFilled, EyeFilled, UserAddOutlined } from '@ant-design/icons';
import { ColumnsType } from "antd/es/table";
import moment from 'moment'
import seekSolutionApi from "../../../utils/seekSolutionApi";
import { Link, useLocation, useMatch } from "react-router-dom";
import uiSettings from "../../../utils/uiSettings";
import { BucketBaseUrl } from "../../../utils/SeekSolutionS3Bucket";
const { Title } = Typography;
const { Search } = Input;

const suffix = (
    <AudioOutlined
        style={{
            fontSize: 16,
        }}
    />
);



const SectionListing = () => {
    const match = useMatch("/courses/:courses_id/sections/page/:pagination")
    const location = useLocation()
    const uRLSearchParams = new URLSearchParams(location.search)
    const [state, setState] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingId, setLoadingId] = React.useState("");

    console.log("match=>",match);
    


    const initialise = async () => {
        try {
            let apiRes = await seekSolutionApi.Sections.list(match?.params.courses_id as string)
            console.log('apiRes', apiRes);

            setState(apiRes)
        } catch (error) {

        }
    }

    const columns: ColumnsType<any> = [
      
        {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text) => (
                text
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (_: any, record: any) => (
                <Flex gap={"small"}>
                    <Link to={`/lectures/${record._id}/page/1`}>
                        <Button icon={<EyeFilled/>}></Button>
                    </Link>
                    <Link to={`/courses/${match?.params.courses_id}/sections/${record._id}/edit`} state={record}>
                        <Button  icon={<EditFilled/>}></Button>
                    </Link>


                    <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                    console.log("thisis recofd id ",record._id);
                    
                  handleDelete(record._id);
                }}
              >
                <Button danger  loading={loadingId === record._id}>
                  <DeleteOutlined/>
                </Button>
              </Popconfirm>
                </Flex>
            ),
        },
    ];
    
 //---------------------------------------
 const handleDelete = async (id: any) => {
    console.log("dlt id", id);

    try {
      setLoading(true);
      setLoadingId(id);
      let apiRes = await seekSolutionApi.Sections.delete(id);
      console.log("deleted id1", apiRes);
      if (apiRes) {
        console.log("deleted id", apiRes);
        initialise();
        // window.alert("Item Deleted Successfully");
      }
    } catch (error) {
      console.error("Error deleting sections:", error);
    } finally {
      setLoading(false);
      
    }
  };

    React.useEffect(() => {
        initialise()
    }, [])
    
    return <Fragment>
        <Space direction="vertical" style={{ display: 'flex' }}>
            <Breadcrumb
                items={[
                    {
                        title: 'Management',
                    },
                    {
                        title: 'Courses',
                    },
                    {
                        title: match?.params.courses_id,
                    },
                    {
                        title: "Sections",
                    },
                ]}
            />
            <Flex justify="space-between" gap={"small"} align="center" >
            <Title level={2}>Sections</Title>
          
          <Link to={`/courses/${match?.params.courses_id}/sections/create`}>
          <Button icon={<UserAddOutlined/>}></Button></Link>
          </Flex>

            <Divider />
            <Row gutter={[2, 8]} justify={'space-between'}>
                <Col span={20} >
                    <Search
                        placeholder="input search text"
                        enterButton="Search"
                        suffix={suffix}
                    />
                </Col>
                <Col span={4} >
                <Link to={`/courses/${match?.params.courses_id}/sections/create`}>
                        <Button type="primary">
                            Add Section
                        </Button>
                    </Link>
                </Col>
            </Row>

            <Table columns={columns} dataSource={state} pagination={{ hideOnSinglePage: true }} />
        </Space>

    </Fragment>

}

export default SectionListing